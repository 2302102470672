import {Controller} from 'stimulus';

/** Class that handles logic focused around copying specific text to the clipboard on events. */
export default class extends Controller {
  static targets = ['button', 'text'];

  /**
   * Copies text visible in the textTarget to the clipboard. Also changes the buttonTarget text to indicate that the
   * text was successfully copied for 2 seconds and then reverts the text back to indicate that the action can be
   * performed again.
   */
  copy() {
    const range = document.createRange();
    range.selectNode(this.textTarget);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    const button = this.buttonTarget;
    button.innerHTML = 'Copied!';
    setTimeout(function() {
      button.innerHTML = 'Copy';
    }, 2000);
  }
}
