import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'

import Indicator from './Indicator'

function Test({ test, indicator_cursor }) {
  const [loading, setLoading] = useState(true)
  const [showIntroModal, setShowIntroModal] = useState(false)
  const [indicators, setIndicators] = useState([])
  const [indicator, setIndicator] = useState(null)
  const [indicatorCursor, setIndicatorCursor] = useState(indicator_cursor)
  const [errorText, setErrorText] = useState(null)

  useEffect(() => {
    if (test.started_at === null || test.started_at === undefined) {
      setShowIntroModal(true)
    }

    fetchIndicators()
    setIndicator(indicators[indicatorCursor])
  }, [])

  useEffect(() => {
    if (indicators.length === 0) return

    setIndicator(indicators[indicatorCursor])
  }, [indicatorCursor, indicators])

  const fetchIndicators = async () => {
    const resp = await axios.get(`/api/tests/${test.id}/indicators`)

    if (resp.status === 200) {
      setLoading(false)
      setIndicators(resp.data.indicators)
    } else {
      return
    }
  }

  const updateCursor = (cursor) => {
    setErrorText(null)

    if (cursor < 0) {
      setIndicatorCursor(0)
    } else if (cursor > 199) {
      setIndicatorCursor(199)
    } else {
      setIndicatorCursor(cursor)
    }
  }

  const updateIndicator = async (rank) => {
    if (indicator.rank !== rank) { 
      const token = document.querySelector('[name=csrf-token]').content;
      axios.defaults.headers.common['X-CSRF-TOKEN'] = token

      const resp = await axios.put(`/api/tests/${test.id}/indicators/${indicator.id}`, 
      { rank: rank })
      
      if (resp.status === 200) {
        setIndicator(resp.data.indicator)
        indicators[indicatorCursor] = resp.data.indicator
        updateCursor(indicatorCursor + 1)
        setErrorText(null)
      } else {
        setErrorText("There was an error updating this indicator. Please try again.")
      }
    } else {
      updateCursor(indicatorCursor + 1)
    }
  }

  const onComplete = async () => {
    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    const resp = await axios.post(`/api/tests/${test.id}/result_sets`)

    if (resp.status === 201) {
      window.location.href = resp.headers.location
    } else {
      setErrorText("There was an error submitting this test. Please try again.")
    }
  }

  const IndicatorModuleLoading = () => {
    return (
      <div className="indicator-module-loading d-flex flex-column align-items-center">
        <h3 className="text-primary h3 mb-5">Loading...</h3>
        <i className="display-4 fas fa-spinner fa-spin text-primary mt-3"/>
      </div>
    )
  }

  const IndicatorModule = () => {
    return (
      <div className="d-flex flex-column justify-content-center full-page-content align-self-center h-100">
        <div className="card border-0 bg-main flex flex-column align-items-center">
          { errorText &&
            <div className="d-flex flex-row"> 
              <i className="h5 fas fa-exclamation-triangle text-primary mt-2 mb-0 me-2"/>
              <h3 className="text-primary text-center">{errorText}</h3>
            </div>
          }
          {indicator && <Indicator indicator={indicator} updateCursor={updateCursor} updateIndicator={updateIndicator} onComplete={onComplete} updateErrorText={setErrorText}/>}
        </div>
      </div>
    )
  }

  return(
    <>
      <div className="card border-0 my-5 bg-main">
        {loading ? <IndicatorModuleLoading/> : <IndicatorModule/> }
      </div>
      <Modal scrollable={true} size="xl" centered="true" show={showIntroModal} onHide={() => (setShowIntroModal(false))}>
        <Modal.Body className="border-3 border-primary rounded-3">
          <div className="d-flex flex-column">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center align-items-md-end mx-3">
              <h2 className="cormorantgaramond-regular mb-0">Before we begin:</h2>
              <h6 className="sofia-pro-regular mb-0 d-none d-md-block">INTRO</h6>
            </div>
            <hr className="mt-1"/>
            <p>
              The purpose of this test is to measure patterns of behavior and attitudes that have been typical for you for
              most of your life. There are no right or wrong answers. Starting with the first question shown, indicate how
              well each word or phrase describes you. If you are wondering what the item means, place your cursor over
              the 'i' icon or hold down the 'i' key on your keyboard and a definition will appear. Each answer selected
              will automatically move forward to the next term. If you would like to revisit and change your answer on a term
              you may navigate backwards using the 'Previous' button. In order to continue your test after navigating backward,
              use the 'Next' button to travel forward in the list of terms.
            </p>
            <p>
              The WEPSS takes approximately 30 to 50 minutes to complete. Your progress will be saved every time you
              answer and move on to a new term and you can easily leave and come back to the place where you left off.
            </p>
            <p>
              Once you have answered all 200 questions, your test will be scored and you will immmediately be directed to
              the results page where you may choose to save your results as a PDF document.
            </p>
            <div className="mt-5 mx-5">
              <p className="text-center mt-3">
                Material from the WEPSS copyright &#169; 1999 by Western Psychological Services. Format adapted by C. Roth,
                CrossPoint Ministry, for specific, limited research use under license of the publisher, WPS (rights@wpspublish.com).
                No additional reproduction, in whole or in part, by any medium or for any purpose, may be made without the prior,
                written authorization of WPS. All rights reserved.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button className="btn border-0" type="button" onClick={() => (setShowIntroModal(false))}>
            Start Profile &#x27F6;
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Test
