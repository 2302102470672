import {Controller} from 'stimulus';

/** Class that handles Company focused logic. */
export default class extends Controller {
  static targets = ['demoteAdminModal', 'errors', 'name', 'slug', 'usersContainer', 'assignTestModal',
    'promoteAdminModal', 'currentSlug'];

  /**
   * Fires when the Company controller connects to the DOM.
   *
   * If the assignTestModal target is present, it adds a listener to the modal to load the modal with User data when
   * it is shown by a User action.
   *
   * If the demoteAdminModal is present, it adds a listener to the modal to load the modal with User data when it is
   * shown by a User action.
   *
   * If the promoteAdminModal is present, it adds a listener to the modal to load the modal with User data when it is
   * shown by a User action.
   */
  connect() {
    if (this.targets.find('assignTestModal')) {
      const controller = this;

      this.assignTestModalTarget.addEventListener('show.bs.modal', function(event) {
        controller.loadAssignTestModal(event);
      });
    }

    if (this.targets.find('demoteAdminModal')) {
      const controller = this;

      this.demoteAdminModalTarget.addEventListener('show.bs.modal', function(event) {
        controller.loadDemoteAdminModal(event);
      });
    }

    if (this.targets.find('promoteAdminModal')) {
      const controller = this;

      this.promoteAdminModalTarget.addEventListener('show.bs.modal', function(event) {
        controller.loadPromoteAdminModal(event);
      });
    }
  }

  /**
   * Loads the Demote Admin Modal with all pertinent information for the User to be able to Demote the Admin.
   * @param {Event} event - A click event on the button which launches this modal.
   */
  loadDemoteAdminModal(event) {
    const button = event.relatedTarget;
    const userFullNameData = button.getAttribute('data-bs-user-full-name');
    const userIdData = button.getAttribute('data-bs-user-id');

    const demoteMessage = this.demoteAdminModalTarget.querySelector('#modal-demote-message');
    const form = this.demoteAdminModalTarget.querySelector('#modal-company-demote-admin-form');

    demoteMessage.innerHTML = `Are you sure you want to demote ${userFullNameData}?`;
    form.action = `${window.location.href}/demote_admin?user_id=${userIdData}`;
  }

  /**
   * Loads the Promote Admin Modal with all the pertinent information for the User to be able to Promote a User.
   * @param {Event} event - A click event on the button which launches this modal.
   */
  loadPromoteAdminModal(event) {
    const button = event.relatedTarget;
    const userFullNameData = button.getAttribute('data-bs-user-full-name');
    const userIdData = button.getAttribute('data-bs-user-id');

    const promoteMessage = this.promoteAdminModalTarget.querySelector('#modal-promote-message');
    const form = this.promoteAdminModalTarget.querySelector('#modal-company-promote-admin-form');

    promoteMessage.innerHTML = `Are you sure you want to promote ${userFullNameData}?`;
    form.action = `${window.location.href}/promote_admin?user_id=${userIdData}`;
  }

  /**
   * Loads the assignTestModal target with pertinent User data.
   * @param {Event} event - A show.bs.modal Event.
   */
  loadAssignTestModal(event) {
    const button = event.relatedTarget;
    const userFullNameData = button.getAttribute('data-bs-user-full-name');
    const userIdData = button.getAttribute('data-bs-user-id');

    const userFullName = this.assignTestModalTarget.querySelector('#modal-user-full-name');
    const form = this.assignTestModalTarget.querySelector('#modal-assign-test-form');

    userFullName.innerHTML = `Assign a Test to ${userFullNameData}?`;
    form.action = `${window.location.href}/users/${userIdData}/assign_test`;
  }

  /**
   * Generates a slug for the Company based on the name of the Company.
   * @param {Event} event - A change event on the nameTarget most likely.
   */
  generateSlug(event) {
    this.slugTarget.value = event.currentTarget.value.replace(/\s+/g, '-').toLowerCase();
    this.validateSlug();
  }

  /**
   * Validates that the current Slug does not exist for a Company already.
   */
  validateSlug() {
    const headers = {
      'X-REQUESTED-WITH': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
    };

    fetch(`/companies?slug=${this.slugTarget.value}`, {headers: headers})
        .then((response) => (response.json()))
        .then((response) => {
          const slugs = response.data.map((company) => (company.attributes.slug));

          if (this.slugTarget.value === this.currentSlugTarget.innerHTML) {
            this.slugTarget.classList.add('is-valid');
            this.slugTarget.classList.remove('is-invalid');
            this.slugTarget.nextElementSibling.innerHTML = '';
          } else if (slugs.find((slug) => (slug === this.slugTarget.value))) {
            this.slugTarget.classList.add('is-invalid');
            this.slugTarget.classList.remove('is-valid');
            this.slugTarget.nextElementSibling.innerHTML = `There is already a Company using this URL slug. Please use a
                                                            different one.`;
          } else {
            this.slugTarget.classList.add('is-valid');
            this.slugTarget.classList.remove('is-invalid');
            this.slugTarget.nextElementSibling.innerHTML = '';
          }
        });
  }

  /**
   * Attaches the received error HTML to the errorTarget.
   * @param {Event} event - An ajax:error event, most likely.
   */
  onCreationFailure(event) {
    const [, , xhr] = event.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }

  /**
   * Loads Users from API that are associated with this Company.
   */
  loadUsers() {
    const headers = {
      'X-REQUESTED-WITH': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
    };

    const copmanySlug = this.slugTarget.dataset.companySlug;

    fetch(`/companies/${copmanySlug}/users`, {headers: headers})
        .then((response) => (response.json()))
        .then((response) => {
          const userCards = response.data.map((userData) => this.buildUserCard(userData));
          userCards.forEach((card) => this.usersContainerTarget.insertAdjacentElement('beforeend', card));
        });
  }

  /**
   * Builds a single User Card
   * @param {Object} userData - A JSON object representing a single User.
   * @return {Element} A fully built User card.
   */
  buildUserCard(userData) {
    const column = this.buildElement('div', 'col d-flex flex-column');
    const card = this.buildElement('div', 'card border-0');
    const cardBody = this.buildElement('div', 'card-body');
    const fullName = this.buildElement('a', 'h1 font-cg-regular',
        `${userData.attributes.first_name} ${userData.attributes.last_name}`);
    const userLink = this.buildLink(`/users/${userData.id}`, '', fullName);
    const hr = this.buildElement('hr', 'hr-bold');
    cardBody.appendChild(userLink);
    card.appendChild(cardBody);
    column.appendChild(card);
    column.appendChild(hr);
    return column;
  }

  /**
   * Builds the specified type tag based off of the provided parameters.
   * @param {String} type - The type of HTML element to build.
   * @param {String} classes - The classes that should be added to the Element, if any.
   * @param {Element} innerHTML - The innerHTML that should be inserted into the Element, if any.
   * @return {Element} A completely built Element Object.
   */
  buildElement(type, classes = null, innerHTML = null) {
    const element = document.createElement('div');

    if (classes) {
      element.className += classes;
    }

    if (innerHTML) {
      element.innerHTML = innerHTML;
    }

    return element;
  }

  /**
   * Builds an <a> tag link based off of the provided parameters.
   * @param {String} href - The HREF the link should go to.
   * @param {String} classes - The classes that should be added to the <a>, if any.
   * @param {Element} innerHTML - The innerHTML that should be inserted into the <a>, if any.
   * @return {Element} A completely built <a> Element Object.
   */
  buildLink(href = '#', classes = null, innerHTML = null) {
    const a = document.createElement('a');
    a.href = href;

    if (classes) {
      a.className += classes;
    }

    if (innerHTML) {
      a.appendChild(innerHTML);
    }

    return a;
  }

  /**
   * Cleans up Posts on disconnect. There was an issue with caching view_counts when navigating using the 'Back' button
   * in the browser. This way view_counts are always up to date.
   */
  disconnect() {
    if (this.targets.find('usersContainer')) {
      while (this.usersContainerTarget.children.length > 0) {
        this.usersContainerTarget.children[0].remove();
      }
    }
  }
}
