import {Controller} from 'stimulus';

/**
 * Validates the attached form for any fields with is-invalid on them as well as validates required fields
 * have values.
 * */
export default class extends Controller {
  static targets = ['form', 'field', 'required'];

  /**
   * Fires when this controller connects to the DOM. Loops through each of the defined formTargets and decides if any
   * targets have errors. Prevents default behavior and stops event propagation if any field is found to have is-invalid
   * class defined.
   */
  connect() {
    this.formTargets.forEach( (form) => {
      form.addEventListener('submit', (event) => {
        this.requiredTargets.find((target) => {
          if (this.isBlank(target)) {
            target.classList.add('is-invalid');
          }
        });

        if (this.fieldTargets.find((target) => target.classList.contains('is-invalid'))) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }
      }, false);
    });
  }

  /**
   * Determines if the provided field is blank.
   * @param {Object} field - A field in the DOM.
   * @return {boolean} True if the field does have a value, false otherwise.
   */
  isBlank(field) {
    return field.value === '' || field.value === null || field.value === undefined;
  }
}
