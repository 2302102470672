import React from 'react';

function BillingInformation(props) {
  const evaluateValidity = (e) => {
    const field = e.target;

    if (field.required && !field.value || field.value === '') {
      field.classList.add('is-invalid');
      field.classList.remove('is-valid');
    } else {
      field.classList.add('is-valid');
      field.classList.remove('is-invalid');
    }
  }

  return (
    <div className="my-4">
      <div className="h3">Billing Information</div>
      <hr className="hr-bold mt-0" />
      <div className="row g-3 mx-0 mx-md-1">
        <div className="col-md-6">
          <label className="h5" htmlFor="first-name">First Name</label>
          <input
            id="first-name"
            type="text"
            className="form-control form-control-lg styled-input"
            placeholder="First Name"
            value={props.firstName || ''}
            onChange={e => {
              evaluateValidity(e);
              props.setFirstName(e.target.value);
            }}
            required
          />
          <div className="invalid-feedback">First name is a required field.</div>
        </div>
        <div className="col-md-6">
          <label className="h5" htmlFor="last-name">Last Name</label>
          <input
            id="last-name"
            type="text"
            className="form-control form-control-lg styled-input"
            placeholder="Last Name"
            value={props.lastName || ''}
            onChange={e => {
              evaluateValidity(e);
              props.setLastName(e.target.value);
            }}
            required
          />
          <div className="invalid-feedback">Last name is a required field.</div>
        </div>
        <div className="col-12">
          <label className="h5" htmlFor="email">Email</label>
          <input
            id="email"
            type="text"
            className="form-control form-control-lg styled-input"
            placeholder="Email"
            onChange={e => {
              evaluateValidity(e);
              props.setEmail(e.target.value);
            }}
            required
            value={props.email || ''}
          />
          <div id="emailHelp" className="form-text">A receipt will be emailed to this email address.</div>
          <div className="invalid-feedback">An email address is required. A receipt will sent to this email upon on successful checkout.</div>
        </div>
        <div className="col-12">
          <label className="h5" htmlFor="address">Address</label>
          <input
            id="address"
            type="text"
            className="form-control form-control-lg styled-input"
            placeholder="Address"
            onChange={e => {
              evaluateValidity(e);
              props.setAddress(e.target.value);
            }}
            required
            autoFocus
          />
          <div className="invalid-feedback">Address is a required field.</div>
        </div>
        <div className="col-12">
          <label className="h5" htmlFor="city">City</label>
          <input
            id="city"
            type="text"
            className="form-control form-control-lg styled-input"
            placeholder="City"
            onChange={e => {
              evaluateValidity(e);
              props.setCity(e.target.value);
            }}
            required
          />
          <div className="invalid-feedback">City is a required field.</div>
        </div>
        <div className="col-12 col-md-6">
          <label className="h5" htmlFor="state">State</label>
          <input
            id="state"
            type="text"
            className="form-control form-control-lg styled-input"
            placeholder="State"
            onChange={e => {
              evaluateValidity(e);
              props.setState(e.target.value);
            }}
            required
          />
          <div className="invalid-feedback">State is a required field.</div>
        </div>
        <div className="col-12 col-md-6">
          <label className="h5" htmlFor="postal-code">Postal Code</label>
          <input
            id="postal-code"
            type="text"
            className="form-control form-control-lg styled-input"
            placeholder="Postal Code"
            onChange={e => {
              evaluateValidity(e);
              props.setPostalCode(e.target.value);
            }}
            required
          />
          <div className="invalid-feedback">Postal Code is a required field.</div>
        </div>
        <div className="my-2" />
      </div>
    </div>
  )
}

export default BillingInformation;
