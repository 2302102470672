import React, { Fragment, useEffect, useRef, useState } from 'react'; // eslint-disable-line no-unused-vars

import axios from 'axios';

function InviteModal({ company, availableTests, setAvailableTests }) {
  const purchaseLink = () => {
    return <a href={`/companies/${company.slug}/purchase`}>purchase</a>
  }

  const removeTest = (test) => {
    let temp = availableTests;
    temp[temp.indexOf(test)].inviteEmail = test.inviteEmail;
    setAvailableTests(temp.map((test) => test));
  }

  return (
    <div
      className="modal fade"
      id="invitation-link-modal"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content border-3 border-primary rounded-3">
          <div className="modal-header">
            <div className="h2 modal-title">INVITE USERS</div>
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column">
              <div className="card mx-0 mx-md-3 my-3 border-0">
                <div className="card-body bg-white p-0">
                  {availableTests.filter((test) => !test.inviteEmail).length != 0 && availableTests.map((test) => (
                    <TestInviteForm company={company} onInvite={removeTest} test={test} key={test.id}/>
                  ))}
                  {availableTests.filter((test) => !test.inviteEmail).length == 0 &&
                    <div>
                      <h5>No Tests available to invite to. Please {purchaseLink()} more Tests in order to send more invites.</h5>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function TestInviteForm(props) {
  const [inviteSent, setInviteSent] = useState(false)
  const emailField = useRef();
  const banner = useRef();

  useEffect(() => {
    if (inviteSent) {
      props.onInvite(props.test)
      hideBanner();
    }
  }, [inviteSent])

  const hideBanner = () => {
    setTimeout(() => {banner.current.classList.add('opacity-0')}, 1000);
    setTimeout(() => {banner.current.classList.add('d-none')}, 3000)
  }

  const submitInvite = async (e) => {
    e.preventDefault();

    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    if (validateEmail()) {
      const response = await axios.post(`${props.company.slug}/invite`, {
        test_id: props.test.id,
        email: emailField.current.value
      })

      if (response.status === 200) {
        props.test.inviteEmail = emailField.current.value;
        setInviteSent(true);
      } else {
        setInviteSent(false);
      }
    } else {
      emailField.current.classList.add('is-invalid');
    }
  }

  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len
    if (emailField.current.value === '') {
      emailField.current.classList.remove('is-invalid');
      emailField.current.classList.remove('is-valid');
      return false;
    } else {
      if (emailField.current.value.match(re)) {
        emailField.current.classList.remove('is-invalid');
        emailField.current.classList.add('is-valid');
        return true;
      } else {
        emailField.current.classList.remove('is-valid');
        emailField.current.classList.add('is-invalid');
        return false;
      }
    }
  }

  return (
    <Fragment>
      {!inviteSent &&
        <Fragment>
          <div className="my-4">
            <form
              className="d-flex flex-column flex-md-row needs-validation"
              data-form-target="form"
              onSubmit={submitInvite}
              remote="true"
              noValidate={true}
            >
              <div className="row g-3 mx-0 mx-md-1 w-100">
                <div className="col-md-9">
                  <label className="h5">Email</label>
                  <input
                    type="text"
                    className="form-control form-control-lg styled-input"
                    placeholder="Email"
                    name="company[email]"
                    data-user-target="email"
                    data-action="user#validateEmail"
                    ref={emailField}
                    onChange={validateEmail}
                    required
                  />
                  <div className="invalid-feedback">A valid email address is required.</div>
                </div>
                <div className="col-md-3 d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-pill btn-primary text-white"
                    type="submit"
                  >Invite</button>
                </div>
              </div>
            </form>
          </div>
          <hr className="hr-bold" />
        </Fragment>
      }
      {props.test.inviteEmail &&
        <div className="card border-success invitation-removed my-4" ref={banner}>
          <div className="card-body text-success bg-white rounded-3">
            <h3>Invite to {props.test.inviteEmail} successfully sent.</h3>
          </div>
        </div>
      }
    </Fragment>
  )
}

export default InviteModal;
