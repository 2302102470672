import {Controller} from 'stimulus';

/** Handles all validation of required fields in forms. */
export default class extends Controller {
  static targets = ['field'];

  /**
   * Validates that the Event's currentTarget is not blank. If the target is found to be blank, the 'is-invalid' class
   * is attached to the target. If the target is not found to be blank, the 'is-valid' class is attached to the target.
   * @param {Event} event - An Event fired on a required input.
   */
  validateRequired(event) {
    if (event.currentTarget.value === '' ||
        event.currentTarget.value === null ||
        event.currentTarget.value === undefined) {
      event.currentTarget.classList.add('is-invalid');
      event.currentTarget.classList.remove('is-valid');
    } else {
      event.currentTarget.classList.add('is-valid');
      event.currentTarget.classList.remove('is-invalid');
    }
  }
}
