import React, { useState, useRef } from 'react'
import axios from 'axios'

function CreateCompanyForm() {
  const [name, setName] = useState("")
  const [slug, setSlug] = useState("")
  const [slugFeedback, setSlugFeedback] = useState("")
  const [errorText, setErrorText] = useState("")

  const slugRef = useRef(null)

  const updateName = (name) => {
    setName(name)
    updateSlug(generateSlug(name))
  }

  const generateSlug = (name) => {
    return name.replace(/\s+/g, '-').toLowerCase()
  }

  const updateSlug = (slug) => {
    setSlug(slug)
    validateSlug(slug)
  }

  /**
   * Validates that the current Slug does not exist for a Company already.
   */
  const validateSlug = async (_slug) => {
    if (_slug.length === 0) return true

    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    const resp = await axios.get(`/api/companies?slug=${_slug}`)

    if (resp.status === 200) {
      const slugs = resp.data.companies.map((company) => (company.slug));

      if (slugs.find((s) => (s === _slug))) {
        slugRef.current.classList.remove('is-valid')
        slugRef.current.classList.add('is-invalid')
        setSlugFeedback("This slug has been already taken. Please use a different one.")
        return false
      } else {
        slugRef.current.classList.remove('is-invalid')
        slugRef.current.classList.add('is-valid')
        setSlugFeedback(null)
        return true
      }
    }
  }

  const submit = async () => {
    const valid = await validateSlug(slug)
    if (!valid) return

    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    const resp = await axios.post(`/api/companies`, { name: name, slug: slug })

    if (resp.status === 201) {
      setErrorText("")
      window.location.href = resp.headers.location
    } else {
      setErrorText("There was an error creating the Company. Please try again.")
    }
  }

  return (
    <div className="card full-page-content border-0">
      <div className="card-header border-0 p-0">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="display-3 cormorantgaramond-regular">Create Company</div>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="w-75">
            {errorText}
          </div>
          <div className="w-100 needs-validation my-3">
            <div className="row g-4 mx-0 mx-md-5">
              <div className="col-12">
                <label className="h5" htmlFor="company_name">Company Name (optional)</label>
                <input 
                  type="text" 
                  id="company_name" 
                  className="form-control form-control-lg styled-input" 
                  autoFocus={true} 
                  placeholder="Company Name"
                  value={name}
                  onChange={(e) => (updateName(e.target.value))}
                />
              </div>
              <div className="col-12 my-3 my-md-5">
                <label className="h5" htmlFor="company_slug">URL slug (optional)</label>
                <input 
                  type="text"
                  id="company_slug"
                  ref={slugRef}
                  className="form-control form-control-lg styled-input"
                  placeholder="Slug"
                  value={slug}
                  onChange={(e) => (updateSlug(e.target.value))}
                />
                <div className="invalid-feedback">
                  {slugFeedback}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center actions mt-3">
              <button className="btn-pill btn btn-primary text-white" onClick={() => (submit())}>Create Company</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCompanyForm
