import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';

function PdfDownloader(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const button = () => {
    if (props.circle) {
      return (
        <a
          title="Download PDF Report"
          className={
            classnames('btn', 'btn-outline-primary', 'bg-white', 'text-primary',
                        { 'rounded-circle': isMobile, 'sticky-button': isMobile,
                          'btn-pill': !isMobile, 'sticky-button-desktop': !isMobile })}
          href={`/result_sets/${props.result_set_id}/pdf.pdf`}
        >
          { isMobile ? '' : 'Download PDF' }
          <i key="1" className="fas fa-download ms-0 ms-md-2" />
        </a>
      )
    } else {
      return (
        <a
          className="btn border-0 text-primary fs-4"
          title="Download PDF Report"
          href={`/result_sets/${props.result_set_id}/pdf.pdf`}
        >
          Download Results
          <i key="1" className="ms-2 fas fa-download"/>
        </a>
      )
    }
  }

  return (
    <Fragment>
      {button()}
    </Fragment>
  )
}

export default PdfDownloader;
