import React, {useState} from 'react'; // eslint-disable-line no-unused-vars
import InviteModal from './InviteModal.js'; // eslint-disable-line no-unused-vars

function AccountDetails({ company }) {
  const getAvailableTests = () => {
    return company.tests.filter((test) => test.status === 'available');
  }

  const [availableTests, setAvailableTests] = useState(getAvailableTests());

  const getCompleteTests = () => {
    return company.tests.filter((test) => test.state === 'complete');
  }

  return (
    <div className="card border-0 w-100">
      <div className="card-body">
        <div className="d-flex flex-column">
          <strong className="h2 letter-spacing-3 text-start ms-2">Account Details</strong>
          <hr className="hr-bold my-0" />
          <div className="d-flex flex-column align-items-start w-100">
            <div className="d-flex flex-column flex-md-row ms-2 mt-3 w-100">
              <div className="d-flex flex-column w-100 w-md-50">
                {availableTests.length == 1 &&
                  <h4>You have 1 Test Available</h4>
                }
                {availableTests.length != 1 &&
                  <h4>{`You have ${availableTests.filter((test) => (!test.inviteEmail)).length} Tests available`}</h4>
                }
                <h6 className="text-muted sofia-italic">{`Completed Tests: ${getCompleteTests().length}`}</h6>
                <h6 className="text-muted sofia.italic">{`Total Purchased: ${company.tests.length}`}</h6>
              </div>
              <div className="d-flex flex-column align-items-center align-items-md-end align-self-center my-3 my-md-0 w-100">
                <button
                  className="btn btn-pill btn-lg btn-primary text-white me-md-2"
                  type="button"
                  id="invitation-button"
                  data-bs-toggle="modal"
                  data-bs-target="#invitation-link-modal"
                >
                  Invite Users
                </button>
                <a
                  className="btn btn-pill btn-lg btn-primary text-white text-nowrap me-md-2 my-3"
                  id="purchase-tests-button"
                  href={`/companies/${company.slug}/purchase`}
                >
                    Purchase Tests
                </a>
              </div>
            </div>
            <button
              className="btn border-0 text-dark fs-4"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#company-tests-modal"
            >
              View more details ⟶
            </button>
          </div>
        </div>
      </div>
      <InviteModal
        company={company}
        availableTests={availableTests}
        setAvailableTests={setAvailableTests}
      />
    </div>
  )
}

export default AccountDetails;
