import React, { Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import axios from 'axios';

function QuantityInformation({ company, setDiscountId, isProcessing, pricePerTest, quantity, setQuantity, totalPrice, setTotalPrice }) {
  const [discountCode, setDiscountCode] = useState('');
  const [appliedDiscountCode, setAppliedDiscountCode] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState();
  const [discountError, setDiscountError] = useState(false);

  useEffect(() => {
    setTotalPrice(getTotalPrice());
  }, [appliedDiscountCode, quantity])

  const formatPrice = (price) => {
    if (Number(price) === price) {
      if (price % 1 != 0) {
        return price.toFixed(2);
      } else {
        return price;
      }
    } else {
      return 0;
    }
  }

  const applyDiscount = async () => {
    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    let response = null

    if (company) {
      response = await axios.get(`/discounts?code=${discountCode}&company_id=${company.id}`)
    } else {
      response = await axios.get(`/discounts?code=${discountCode}`)
    }

    if (response.data.discounts.length > 0) {
      setDiscountError(false);
      const discount = response.data.discounts[0];
      setDiscountId(discount.id);
      setDiscountPercentage(discount.percentage / 100);
      setAppliedDiscountCode(discountCode);
      setDiscountCode('');
    } else {
      setDiscountError(true);
    }
  }

  const clearDiscount = () => {
    setDiscountError(false);
    setDiscountCode('');
    setAppliedDiscountCode(null);
    setDiscountPercentage(null);
  }

  const discountPrice = () => {
    return pricePerTest - (pricePerTest * discountPercentage);
  }

  const getTotalPrice = () => {
    if (appliedDiscountCode) {
      return quantity * (Math.round(100 * discountPrice()) / 100);
    } else {
      return quantity * (Math.round(100 * (pricePerTest)) / 100);
    }
  }

  const validateQuantity = (e) => {
    let clean = e.target.value.replace(/\D+/g, '');

    if (!clean || parseInt(clean) < 1) {
      e.target.classList.add('is-invalid');
      e.target.classList.remove('is-valid');
      setQuantity(clean);
    } else {
      e.target.classList.add('is-valid');
      e.target.classList.remove('is-invalid');
      setQuantity(parseInt(clean));
    }
  }

  return (
    <Fragment>
      <div className="card bg-white align-self-center">
        <div className="card-body pt-0">
          <h3 className="mt-4">Quantity Information</h3>
          <hr className="hr-bold mt-0"/>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between my-2">
              <div className="d-flex flex-row">
                <div className="h5 w-100">Ennegram Test</div>
              </div>
              <div className="d-flex flex-row justify-content-end">
                <div className={classnames('h5', { 'text-decoration-line-through': appliedDiscountCode })}>{`$${pricePerTest}`}</div>
                {appliedDiscountCode &&
                  <div className="h5 text-success ms-3">{`$${formatPrice(discountPrice())}`}</div>
                }
              </div>
            </div>
            <div className="align-self-end my-2">
              <div className="input-group my-3">
                <input
                  id="discount-code"
                  type="text"
                  disabled={appliedDiscountCode}
                  className="form-control form-control-lg styled-input me-3"
                  placeholder="Discount Code"
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                />
                <button
                  className="btn btn-outline-primary rounded-pill"
                  type="button"
                  disabled={appliedDiscountCode}
                  onClick={applyDiscount}
                >
                  Apply
                </button>
              </div>
              { appliedDiscountCode &&
                <div className="d-flex flex-row justify-content-end">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-pill px-3 py-1"
                    id="discount-button"
                    onClick={clearDiscount}
                  >
                    <div className="d-flex flex-row align-items-center">
                      <div className="text-uppercase">{appliedDiscountCode}</div>
                      <div className="ms-3">X</div>
                    </div>
                  </button>
                </div>
              }
              { discountError &&
                <div className="h5 text-danger">Invalid discount code!</div>
              }
            </div>
            <div className="d-flex flex-row align-self-center my-2">
              <label className="h5 align-self-end">{'Quantity:  '}</label>
              <input
                id="test-purchase-quantity"
                className="form-control form-control-lg styled-input ms-3"
                type="number"
                value={quantity}
                onChange={e => {
                  validateQuantity(e);
                  setTotalPrice(formatPrice(getTotalPrice()));
                }}
                onKeyDown={(e) => {
                  if (e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '/' || e.key === '-' || e.key === '*') {
                    e.preventDefault();
                  }
                }}
                min="1"
                readOnly={isProcessing}
                required
              />
              <div className="invalid-feedback">At least 1 test must be purchased.</div>
            </div>
            <div className="align-self-center mt-5 mb-2">
              <h2>{`Total Price: $${formatPrice(totalPrice)}`}</h2>
            </div>
            <button
              disabled={isProcessing}
              className="btn btn-pill btn-primary text-white align-self-center"
              type="submit"
            >
              {isProcessing ? 'Processing...' : `Pay $${formatPrice(totalPrice)}`}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default QuantityInformation;
