import React, { useState } from 'react';
import { StripeProvider } from 'helpers/stripe.js';
import BillingInformation from './BillingInformation';
import QuantityInformation from './QuantityInformation';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import axios from 'axios';

export default function PaymentPortalWrapper(props) {
  return (
    <StripeProvider>
      <PaymentPortal {...props} />
    </StripeProvider>
  )
}

function PaymentPortal(props) {
  const [company,] = useState(props.company || {})
  const [firstName, setFirstName] = useState(props.user.first_name);
  const [lastName, setLastName] = useState(props.user.last_name);
  const [email, setEmail] = useState(props.user.email);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [checkoutSuccessful, setCheckoutSuccessful] = useState(false);
  const [redirectId, setRedirectId] = useState();
  const [totalPrice, setTotalPrice] = useState(1 * props.price_per_test);
  const [discountId, setDiscountId] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        fontFamily: 'Sofia-Pro-Bold, sans-serif',
        fontWeight: 500,
        fontSize: '1.25rem'
      }
    },
    classes: {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    }
  }

  const submitPayment = async (event) => {
    event.preventDefault();
    setCheckoutError(false);

    if (formIncomplete()) return;

    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    let billingDetails = {
      name: `${firstName} ${lastName}`,
      email: email,
      address: {
        city: city,
        line1: address,
        state: state,
        postal_code: postalCode
      }
    }

    const cardElement = elements.getElement('card');

    setIsProcessing(true);
    if (totalPrice != 0) {
      try {
        const { data: response } = await axios.post("/api/payment_intents", {
          receipt_email: email,
          amount: totalPrice * 100,
          currency: 'usd',
          description: props.company ? 'CP ENNEAGRAM COACH' : 'CP ENNEAGRAM SNGL',
          payment_method_types: ['card'],
          statement_descriptor: props.company ? 'CP ENNEAGRAM COACH' : 'CP ENNEAGRAM SNGL',
        })

        const paymentMethodReq = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: billingDetails
        });

        if (paymentMethodReq.error) {
          setCheckoutError(paymentMethodReq.error.message);
          setIsProcessing(false);
          return;
        }

        const { error } = await stripe.confirmCardPayment(response.clientSecret, {
          payment_method: paymentMethodReq.paymentMethod.id
        });

        if (error) {
          setCheckoutError(error.message);
          setIsProcessing(false);
          return;
        }

        setIsProcessing(false);
        onSuccessfulCheckout(response.paymentIntentId);
      } catch (err) {
        setIsProcessing(false);
        setCheckoutError(`There was an issue with your request. (${err.response.status})`);
      }
    } else {
      try {
        setIsProcessing(false);
        onSuccessfulCheckout(null);
      } catch (err) {
        setIsProcessing(false);
        setCheckoutError(`There was an issue with your request. (${err.response.status})`)
      }
    }
  }

  const onSuccessfulCheckout = async (paymentIntentId) => {
    setCheckoutSuccessful(true);
    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    const { data: response } = await axios.post("/transactions", {
      amount: totalPrice * 100,
      company_slug: company.slug,
      user_id: props.user.id,
      quantity: quantity,
      discount_id: discountId,
      payment_intent_id: paymentIntentId
    })

    gtag('event', 'conversion', { 'send_to': 'AW-10798194062/G21VCOyVtIADEI67_Zwo', 'transaction_id': response.transaction.id });

    if (company.slug) {
      axios.post(`/companies/${company.slug}/allocate_tests`, {
        quantity: quantity,
        stripe_payment_intent_id: paymentIntentId
      })

      const redir = setTimeout(() => { window.location.replace(`/companies/${company.slug}`) }, 10000);
      setRedirectId(redir);
    } else {
      axios.post(`/users/${props.user.id}/allocate_tests`, {
        quantity: quantity,
        stripe_payment_intent_id: paymentIntentId
      })

      const redir = setTimeout(() => { window.location.replace(`/users/${props.user.id}`) }, 5000);
      setRedirectId(redir);
    }
  }

  const formIncomplete = () => {
    let invalid = false;

    const fields = document.getElementsByClassName('form-control');
    let incompletes = Array.from(fields).filter(field => (field.required && (!field.value || field.value === '')));

    if (incompletes.length > 0) {
      incompletes.forEach(field => field.classList.add('is-invalid'));
      document.getElementById('payment-form').classList.add('was-validated');
      invalid = true;
    } else {
      const quantity = document.getElementById('test-purchase-quantity');
      if (Number(quantity.value) < 1) {
        quantity.classList.add('is-invalid');
        invalid = true;
      } else {
        document.getElementById('payment-form').classList.add('was-validated');
        invalid = false;
      }
    }

    return invalid;
  }

  const displayErrors = () => {
    if (checkoutError) {
      return (
        <div className="card border border-danger bg-white align-self-center mb-3">
          <div className="card-body text-danger">
            <div id="error-explanation">
              <h4 className="card-title text-center"> There were errors submitting your payment for processing.</h4>
              <div className="card-text">
                <ul>
                  <li>{checkoutError}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return [];
    }
  }

  return (
    <div className="d-flex flex-column align-items-center align-self-center bg-main w-100">
      <div className="card border-0 mx-md-2">
        <div className="card-header border-0 py-md-5 py-3 px-0">
          <div className="d-flex flex-column align-items-center justify-content-center">
            {!checkoutSuccessful && <div className="display-4 cormorantgaramond-regular">Purchase Tests</div>}
            <div className="w-100">
              <div className="card border-0 my-3">
                {checkoutSuccessful &&
                  <div className="card-body border-0 d-flex flex-column align-items-center text-center">
                    <h1 className="my-5">Success!</h1>
                    <h3 className="my-3">You purchased {quantity} {quantity === 1 ? 'Enneagram Profile' : 'Enneagram Profiles'}</h3>
                    <h6>
                      {"We're processing your request. You should be redirected in 10 seconds. Please click "}
                      <a
                        href={company.slug ? `/companies/${company.slug}` : `/users/${props.user.id}`}
                        onClick={() => (clearTimeout(redirectId))}>here</a>
                      {" if not redirected."}
                    </h6>
                  </div>
                }
                {!checkoutSuccessful &&
                  <div className="card-body border-0 d-flex flex-column">
                    {displayErrors()}
                    <form
                      id="payment-form"
                      className="d-flex flex-column flex-md-row needs-validation"
                      onSubmit={submitPayment}
                      remote="true"
                      noValidate={true}
                    >
                      <div className="w-100 mx-1 mx-md-5">
                        <BillingInformation
                          firstName={firstName || ''}
                          lastName={lastName || ''}
                          email={email || ''}
                          setFirstName={setFirstName}
                          setLastName={setLastName}
                          setEmail={setEmail}
                          setAddress={setAddress}
                          setCity={setCity}
                          setState={setState}
                          setPostalCode={setPostalCode}
                        />
                        <div className="my-4">
                          <div className="h3">Payment Information</div>
                          <hr className="hr-bold mt-0" />
                          <div className="row g-2 mx-0 mx-md-1">
                            <div className="col-12">
                              <label className="h5" htmlFor="card-number">Card number</label>
                              <CardElement options={CARD_ELEMENT_OPTIONS}/>
                              <hr className="mt-2" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-100 mx-1 mx-md-5">
                        <QuantityInformation
                          company={company}
                          setDiscountId={setDiscountId}
                          isProcessing={isProcessing}
                          quantity={quantity}
                          setQuantity={setQuantity}
                          pricePerTest={props.price_per_test}
                          totalPrice={totalPrice}
                          setTotalPrice={setTotalPrice}
                        />
                      </div>
                    </form>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
