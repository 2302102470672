import {Controller} from 'stimulus';
import List from 'list.js';

/**
 * Controller responsible for initializing List JS lists.
 */
export default class extends Controller {
  /**
   * Fired when this controller connects to the DOM.
   */
  connect() {
    this.list = new List(this.element.id, {
      valueNames: this.element.dataset.listValues.split(' '),
    });
  }
}
