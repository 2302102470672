import React, { useEffect, useRef } from 'react'
import { Tooltip } from 'bootstrap';
import classnames from 'classnames'

function Indicator({ indicator, updateCursor, updateIndicator, onComplete, updateErrorText }) {
  const tooltipRef = useRef(null)
  const tooltipEl = useRef(null)

  useEffect(() => {
    window.addEventListener('keydown', (e) => (showTooltip(e, true)))
    window.addEventListener('keyup', (e) => (showTooltip(e, false)))

    return () => {
      window.removeEventListener("keydown", (e) => (showTooltip(e, true)))
      window.removeEventListener("keyup", (e) => (showTooltip(e, false)))
    }
  }, [])

  useEffect(() => {
    if(!indicator) return

    createTooltip(indicator.definition)
  }, [indicator])

  const createTooltip = (definition) => {
    tooltipEl.current.dataset.bsOriginalTitle = definition
    tooltipRef.current = Tooltip.getOrCreateInstance(tooltipEl.current)
  }

  const showTooltip = (event, shouldShow) => {
    if(shouldShow) {
      if(event?.key === 'i') {
        tooltipRef.current?.show()
      } else {
        return
      }

      tooltipRef.current?.show()
    } else {
      tooltipRef.current?.hide()
    }
  }

  const nextIndicator = () => {
    if (indicator.rank === "unanswered") {
      updateErrorText("You must select a ranking to go to the next term.")
    } else {
      updateCursor(indicator.order + 1)
    }
  }

  const updateIndicatorKeyboard = (event, rank) => {
    if (event.key === "Enter") {
      updateIndicator(rank)
    } else {
      return
    }
  } 

  return (
    <div className="card-header border-0 bg-main mx-2 mx-md-5 p-0 w-100 mt-2">
      { indicator && 
        <div className="d-flex flex-column justify-content-between align-items-end w-100">
          <div className="h5 align-self-center mb-5">{`${indicator.order + 1}/200`}</div>
          <div className="d-flex flex-row align-items-center justify-content-between w-100">
            <div className="display-5 mb-1 cormorantgaramond-regular text-center flex-shrink-1">{indicator.keyword}</div>
            <a 
              className="styled-tooltip" 
              data-bs-toggle="tooltip" 
              data-bs-placement="right" 
              title={indicator.definition}
              ref={tooltipEl}
              onMouseEnter={() => (showTooltip(true))}
              onMouseLeave={() => (showTooltip(false))}
            >
              <i className="h4 fas fa-info-circle text-primary mt-2 mb-0 ms-2"/>
            </a> 
          </div>
        </div>
      }
      <hr className="w-100 mt-0"/>
      <div className="card-body bg-main d-flex flex-column align-items-center mx-2 mx-md-5 p-0">
        <div className="container d-flex flex-column">
          <div className="d-flex flex-column align-items-start mt-2 mb-3 mx-auto">
            <div className="mb-2">
              <div 
                className={classnames("indicator-rank", { "selected": indicator.rank === "never" })} 
                tabIndex="0" 
                onKeyDown={(e) => (updateIndicatorKeyboard(e, "never"))} 
                onClick={() => (updateIndicator("never"))}
              >
                Almost never
              </div>
            </div>
            <div className=" mb-2">
              <div 
                className={classnames("indicator-rank", { "selected": indicator.rank === "rarely" })} 
                tabIndex="0" 
                onKeyDown={(e) => (updateIndicatorKeyboard(e, "rarely"))} 
                onClick={() => updateIndicator("rarely")}
              >
                Rarely or seldom
              </div>
            </div>
            <div className="mb-2">
              <div 
                className={classnames("indicator-rank", { "selected": indicator.rank === "occasionally" })} 
                tabIndex="0" 
                onKeyDown={(e) => (updateIndicatorKeyboard(e, "occasionally"))} 
                onClick={() => (updateIndicator("occasionally"))}
              >
                Occasionally
              </div>
            </div>
            <div className="mb-2">
              <div 
                className={classnames("indicator-rank", { "selected": indicator.rank === "often" })} 
                tabIndex="0" 
                onKeyDown={(e) => (updateIndicatorKeyboard(e, "often"))} 
                onClick={() => (updateIndicator("often"))}
              >
                Frequently or often
              </div>
            </div>
            <div className="mb-2">
              <div 
                className={classnames("indicator-rank", { "selected": indicator.rank === "always" })} 
                tabIndex="0" 
                onKeyDown={(e) => (updateIndicatorKeyboard(e, "always"))} 
                onClick={() => (updateIndicator("always"))}
              >
                Always
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between mt-2">
            <button 
              className={classnames("btn border-0 indicator-action", { "invisible": indicator.order === 0 })} 
              disabled={indicator.order === 0}
              tabIndex="0" 
              type="button" 
              onClick={() => (updateCursor(indicator.order - 1))}
            >
                &#x27F5; Previous
            </button>
            {indicator.order !== 199 ? 
              <button 
                className="btn border-0 indicator-action" 
                tabIndex="0" 
                type="button" 
                onClick={() => (nextIndicator())}
              >
                Next &#x27F6;
              </button>
              :
              <button 
                className={classnames("btn border-0 text-primary btn-lg indicator-action", { "invisible": indicator.rank === "unanswered" })} 
                tabIndex="0" 
                disabled={indicator.rank === "unanswered"} 
                type="button" 
                onClick={() => (onComplete())}
              >
                  Submit &#x27F6;
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  ) 
}

export default Indicator
