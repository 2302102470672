// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('trix');
require('@rails/actiontext');
require('@rails/activestorage').start();
require('channels');

// Import for Bootstrap 5
import '@popperjs/core';
import 'bootstrap';
import {library, dom} from '@fortawesome/fontawesome-svg-core';
import {faDownload, faInfoCircle, faList, faSearch, faSpinner, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {faPlusSquare} from '@fortawesome/free-regular-svg-icons';

library.add(faDownload, faInfoCircle, faList, faPlusSquare, faSearch, faSpinner, faExclamationTriangle);
dom.watch();

// Import of Application stylesheet
import 'stylesheets/application.scss';

// Import Stimulus JS controllers
import 'controllers';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true); // eslint-disable-line no-unused-vars

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
