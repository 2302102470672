import {Controller} from 'stimulus';

/**
 * Controller responsible for sanitizing integer inputs.
 */
export default class extends Controller {
  /**
   * Restricts the inputs that can be added to an input mean only for integers.
   * @param {Event} e - The event.
   */
  filter(e) {
    const code = e.which || e.keyCode;

    if (code < 48 || code > 57) {
      e.preventDefault();
    }
  }
}
