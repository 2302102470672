import {Controller} from 'stimulus';

/** Class that handles Company focused logic. */
export default class extends Controller {
  static targets = ['passwordConfirmation', 'errors', 'currentEmail', 'email', 'password'];

  /**
   * Attaches the received error HTML to the errorTarget.
   * @param {Event} event - An ajax:error event, most likely.
   */
  onSignUpFailure() {
    const [, , xhr] = event.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }

  /**
   * Validates that a valid email address has been entered in the emailTarget field.
   */
  validateRegistrationEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len
    if (this.emailTarget.value === '') {
      this.emailTarget.nextElementSibling.innerHTML = 'Please supply a valid email address';
      this.emailTarget.classList.remove('is-invalid');
      this.emailTarget.classList.remove('is-valid');
    } else {
      if (this.emailTarget.value.match(re)) {
        this.validateNotExistingUser();
      } else {
        this.emailTarget.nextElementSibling.innerHTML = 'Please supply a valid email address';
        this.emailTarget.classList.remove('is-valid');
        this.emailTarget.classList.add('is-invalid');
      }
    }
  }

  /**
   * Validates that a valid email address has been entered in the emailTarget field.
   */
  validateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len
    if (this.emailTarget.value === '') {
      this.emailTarget.classList.remove('is-invalid');
      this.emailTarget.classList.remove('is-valid');
    } else {
      if (this.emailTarget.value.match(re)) {
        this.emailTarget.classList.remove('is-invalid');
        this.emailTarget.classList.add('is-valid');
      } else {
        this.emailTarget.classList.remove('is-valid');
        this.emailTarget.classList.add('is-invalid');
      }
    }
  }

  /**
   * Validates that an existing User is not already registered with the current value provided in the email target.
   * If a current User is found, the field is marked as invalid and a descriptive message is displayed to the User. If
   * a current User is not found with this same email, the field is marked as valid.
   */
  validateNotExistingUser() {
    const headers = {
      'X-REQUESTED-WITH': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
    };

    fetch(`/users?email=${this.emailTarget.value}`, {headers: headers})
        .then((response) => (response.json()))
        .then((response) => {
          const emails = response.data.map((user) => (user.attributes.email));
          if (this.targets.find('currentEmail')) {
            const currentEmailIndex = emails.indexOf(this.currentEmailTarget.value);
            if (currentEmailIndex > -1) {
              emails.splice(currentEmailIndex, 1);
            }
          }
          if (emails.find((email) => (email === this.emailTarget.value))) {
            this.emailTarget.nextElementSibling.innerHTML = `There is already a User signed up with this email address.
                                                             Please use a different one or log in with this account.`;
            this.emailTarget.classList.remove('is-valid');
            this.emailTarget.classList.add('is-invalid');
          } else {
            this.emailTarget.nextElementSibling.innerHTML = 'Please supply a valid email address';
            this.emailTarget.classList.remove('is-invalid');
            this.emailTarget.classList.add('is-valid');
          }
        });
  }

  /**
   * Validates that the passwordTarget and confirmationTarget contain the same value. If the same value is found,
   * the 'is-valid- class is attached to the confirmationTarget. If not, the 'is-invalid' class is attached to the
   * confirmationTarget.
   */
  validateMatch() {
    if (this.passwordConfirmationTarget.value === '') {
      this.passwordConfirmationTarget.classList.remove('is-invalid');
      this.passwordConfirmationTarget.classList.remove('is-valid');
    } else {
      if (this.passwordConfirmationTarget.value !== this.passwordTarget.value) {
        this.passwordConfirmationTarget.classList.remove('is-valid');
        this.passwordConfirmationTarget.classList.add('is-invalid');
      } else {
        this.passwordConfirmationTarget.classList.remove('is-invalid');
        this.passwordConfirmationTarget.classList.add('is-valid');
      }
    }
  }

  /**
   * Validates that the provided password meets the defined requirements.
   *   - At least one:
   *     - uppercase letter (A-Z)
   *     - lowercase letter (a-z)
   *     - digit character (0-9)
   *     - special character (#?!@$%^&*-)
   *
   * If the passwordTarget meets the above criteria, the 'is-valid' class is attached to the target. If not, the
   * 'is-invalid' class is attached to the target.
   */
  validatePwd() {
    const pwdRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,20}$/;
    if (this.passwordTarget.value === '') {
      this.passwordTarget.classList.remove('is-invalid');
      this.passwordTarget.classList.remove('is-valid');
    } else {
      if (this.passwordTarget.value.match(pwdRegex)) {
        this.passwordTarget.classList.remove('is-invalid');
        this.passwordTarget.classList.add('is-valid');
      } else {
        this.passwordTarget.classList.remove('is-valid');
        this.passwordTarget.classList.add('is-invalid');
      }
    }

    this.validateMatch();
  }
}
